import provinceCityData from './provinceCityConfig/provinceCity.json';
import provinceCityMapData from './provinceCityConfig/provinceCityMap.json';
import cityProvinceMapData from './provinceCityConfig/cityProvinceMap.json';
import provinceData from './provinceCityConfig/province.json';
import hotCityData from './provinceCityConfig/hotCityData.json';
import hotForeignData from './provinceCityConfig/hotForeignData.json';
import foreignTreeData from './provinceCityConfig/foreignTreeData.json';

export function getCityTextById (id) {
    return provinceCityData[id];
}

export function getProvinceTextById (id) {
    return provinceCityData[id] || '无数据';
}

export function getCityIdByText (text) {
    return provinceCityData[text] || '';
}

export function getProvinceIdByText (text) {
    return provinceCityData[text] || '';
}

export function getProvince(){
    return provinceData;
}

export function getHotCities(){
    let result = [];
    Object.keys(hotCityData).forEach((id) => {
        result.push({
            id: id,
            text: hotCityData[id]
        })
    })
    return result;
}

export function getCitiesByProvince(pId){
    let result = [], cities;
    cities = provinceCityMapData[pId];
    cities.forEach((id) => {
        result.push({
            id: id,
            text: provinceCityData[id]
        });
    })
    return result;
}

export function getProvinceByCity(cid){
    let pId = cityProvinceMapData[cid], province = provinceCityData[pId];
    return {
        id: pId,
        text: province
    };
}

export function getProvinceById(pId){
    return {
        id: pId,
        text: provinceData[pId]
    }
}

export function getProvinceCityTreeData(){
    let result = [];
    Object.keys(provinceData).forEach((id) =>{
        result.push({
            id: id,
            text: provinceData[id],
            disabled: true,
            children: getCitiesByProvince(id)
        })
    })
    return result;
}

export function getProvinceCityTreeDataWithHotData(){
    let result = getProvinceCityTreeData();
    result.unshift({
        id: 0,
        text: '热门城市',
        disabled:true,
        children:getHotCities()
    })
    return result;
}

export function getForeignData() {
    return foreignTreeData.foreign;
}

export function getForeignHotData(){
    let result = [];
    Object.keys(hotForeignData).forEach(key => {
        result.push({
            id: key,
            text: hotForeignData[key]
        })
    })
    return result;
}

// ko.filter('getCityTextById',getCityTextById);
